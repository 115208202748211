import React, { useState } from "react"
import { Link, useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NotFoundPage from "./404"

// Hooks
import useTranslation from "../hooks/useTranslation"
import useCountryCode from "../hooks/useCountryCode"

// Icons
import SetupIcon from "../assets/icons/pricing-setup.svg"
import TransactionIcon from "../assets/icons/pricing-transactions.svg"
import RefundIcon from "../assets/icons/pricing-refund.svg"
import PayoutIcon from "../assets/icons/pricing-payout.svg"
import EUCardIcon from "../assets/icons/pricing-eu-card.svg"
import NonEUCardIcon from "../assets/icons/pricing-noneu-card.svg"
// import WalletIcon from "../assets/icons/pricing-wallet.svg"
import ThreeDotsIcon from "../assets/icons/three-dots.svg"

// Assets
import VisaLogo from "../assets/logos/visa.svg"
import MastercardLogo from "../assets/logos/mastercard.svg"
import IdealLogo from "../assets/logos/pricing-ideal.png"
// import GiropayLogo from "../assets/logos/pricing-giropay.png"
// import SofortLogo from "../assets/logos/pricing-sofort.png"
// import PaySafeLogo from "../assets/logos/pricing-paysafecard.png"
// import SepaLogo from "../assets/logos/pricing-sepa.png"
// import PostfinanceLogo from "../assets/logos/pricing-post-finance.png"
// import DirectpayLogo from "../assets/logos/pricing-directpay.png"
// import AstropayLogo from "../assets/logos/pricing-astropay.png"
import DashboardStripes from "../assets/aboutus-dashboard-stripes.svg"
import Building from "../assets/building.inline.svg"
// Images
// import Dot from "../assets/dark-dot.svg"

// Styles
import "../styles/pages/pricing.scss"

// Components
import Modal from "../components/modal"

// Inpage Components
const MethodsTable = ({ countryCode, isLoading }) => {
  const { t } = useTranslation()
  const intl = useIntl()
  const isWithComma = intl.locale === "ro" || intl.locale === "it"
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const methods = [
    {
      icon: <EUCardIcon />,
      title: t("EU Cards"),
      text: t(
        "We apply this integrated per-transaction pricing to all Visa and Mastercard bank cards. If the debit or credit card has been issued within the European Economic Area, this is the share that goes to Twispay."
      ),
      logos: [MastercardLogo, VisaLogo],
      rate:
        countryCode === "ro"
          ? isWithComma
            ? "0,99%"
            : "0.99%"
          : isWithComma
          ? "1,3%"
          : "1.3%",
      per: t("per successful transaction"),
    },
    {
      icon: <NonEUCardIcon />,
      title: t("Non-EU Cards"),
      text: t(
        "Perhaps some of your customers are from outside the EEA. Don't worry, you can still accept their orders, as long as they are using Visa, Mastercard, or one of the accepted APMs (details further on)."
      ),
      logos: [MastercardLogo, VisaLogo],
      rate: isLoading
        ? "0.00%"
        : countryCode === "ro"
        ? isWithComma
          ? "2,49%"
          : "2.49%"
        : isWithComma
        ? "2,8%"
        : "2.8%",
      per: t("per successful transaction"),
    },
    // {
    //   icon: <WalletIcon />,
    //   title: t("Alternative Payment Methods"),
    //   text: t(
    //     "Twispay enables you to accept payments from virtually anywhere in the world through any of the following payment methods:"
    //   ),
    //   logos: [
    //     IdealLogo,
    //     GiropayLogo,
    //     SofortLogo,
    //     SepaLogo,
    //     PaySafeLogo,
    //     PostfinanceLogo,
    //     DirectpayLogo,
    //     AstropayLogo,
    //   ],
    //   rate: t("starting from 1.15%"),
    //   per: t("per successful card charge"),
    //   more: {
    //     tooltip: t(
    //       "With Twispay, you can accept the most popular payment methods woldwide"
    //     ),
    //     modal: {
    //       title: t("Which payment methods can I accept through Twispay?"),
    //       text: t(
    //         "Twispay enables you to accept payments from virtually anywhere in the world through any of the following payment methods:"
    //       ),
    //       list: [
    //         "Debit and Credit Cards",
    //         "Ideal",
    //         "GiroPay",
    //         "SEPA Direct Debit",
    //         "Sofortuberweisung",
    //         "Postfinance",
    //         "AstroPay Card",
    //         "AstroPay Direct",
    //         "Banamex",
    //         "Banco de Occidente",
    //         "Banco do Brasil",
    //         "Bancontact",
    //         "Boleto Bancario",
    //         "Bradesco",
    //         "DirectPayEU",
    //         "EPS",
    //         "MyBank",
    //         "Paysafecard",
    //         "POLi",
    //         "Przelewy24",
    //         "Trustpay",
    //         "Verkkopankki",
    //       ],
    //     },
    //   },
    // },
  ]

  return (
    <div className="has-margin-bottom-5">
      <div className="columns is-multiline is-gapless">
        {methods.map((method, i) => (
          <div
            key={method.title}
            className={`column ${i === 2 ? "" : "is-half"} `}
          >
            <hr className={`${i !== 2 ? "is-hidden-tablet" : ""}`} />
            <div className="title-wrapper is-flex is-vcentered is-hspaced">
              <h5 className="title is-4 is-size-5-mobile is-flex is-vcentered has-margin-bottom-0-mobile">
                <span className="icon">{method.icon}</span>
                {method.title}
              </h5>
              {i !== 2 && (
                <span className="is-size-3 is-hidden-tablet rate-value">
                  {method.rate}
                </span>
              )}
            </div>
            <div className="content-wrapper is-hidden-tablet">
              {i === 2 && (
                <span className="is-size-3 is-hidden-tablet rate-value">
                  {method.rate}
                </span>
              )}
              <p className="has-text-secondary  has-margin-bottom-5">
                {method.per}
              </p>
              <p className="has-margin-bottom-5">{method.text}</p>
              <div className={`logos-wrapper ${i === 2 ? "cards-logo" : ""}`}>
                {method.logos.map(logo => (
                  <img
                    style={{
                      height: logo === VisaLogo ? 16 : undefined,
                      padding: logo === IdealLogo ? 20 : undefined,
                    }}
                    className={`${
                      i === 2 ? "card-logo" : "has-margin-right-4"
                    }`}
                    key={logo}
                    src={logo}
                    alt="logo"
                  />
                ))}
                {method.more && (
                  <div className="more-wrapper is-flex is-centered-centered">
                    <button
                      onClick={() => setModalIsOpen(true)}
                      className="is-flex is-centered-centered more-btn card-logo"
                    >
                      <span className="icon ">
                        <ThreeDotsIcon />
                      </span>
                      {t("More")}
                    </button>
                    <Modal
                      title={method.more.modal.title}
                      onClose={() => setModalIsOpen(false)}
                      isOpened={modalIsOpen}
                    >
                      <p className="has-text-default is-size-6 has-margin-bottom-5">
                        {method.more.modal.text}
                      </p>
                      <ul
                        style={{
                          listStyle: "disc",
                          paddingLeft: "32px",
                        }}
                        className="has-text-default has-text-left is-size-6"
                      >
                        {method.more.modal.list.map(item => (
                          <li key={item}> {item}</li>
                        ))}
                      </ul>
                    </Modal>
                  </div>
                )}
              </div>
            </div>
            <div className="content-wrapper is-hidden-mobile">
              <p>{method.text}</p>
              <div
                className={`logos-wrapper ${
                  i === 2 ? "has-margin-bottom-4" : "has-margin-bottom-2"
                } is-flex is-vcentered has-margin-top-4 `}
              >
                {method.logos.map(logo => (
                  <img
                    style={{
                      height: logo === VisaLogo ? 18 : undefined,
                      padding: logo === IdealLogo ? 25 : undefined,
                    }}
                    className={` ${
                      i === 2 ? "card-logo" : "has-margin-right-5"
                    }`}
                    key={logo}
                    src={logo}
                    alt="logo"
                  />
                ))}
                {method.more && (
                  <>
                    <button
                      onClick={() => setModalIsOpen(true)}
                      className="is-flex is-centered-centered more-btn card-logo"
                    >
                      <span className="icon ">
                        <ThreeDotsIcon />
                      </span>
                      {t("More")}
                    </button>
                    <Modal
                      title={method.more.modal.title}
                      onClose={() => setModalIsOpen(false)}
                      isOpened={modalIsOpen}
                    >
                      <p className="has-text-default is-size-5 has-margin-bottom-5">
                        {method.more.modal.text}
                      </p>
                      <ul
                        style={{
                          listStyle: "disc",
                          paddingLeft: "32px",
                        }}
                        className="has-text-default"
                      >
                        {method.more.modal.list.map(item => (
                          <li key={item}> {item}</li>
                        ))}
                      </ul>
                    </Modal>
                  </>
                )}
              </div>
              <span className="is-size-3 rate-value">{method.rate}</span>
              <p className="rate-desc is-size-7">{method.per}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
const StepsTable = ({ countryCode }) => {
  const { t } = useTranslation()
  const intl = useIntl()
  const isWithComma = intl.locale === "ro" || intl.locale === "it"
  const steps = [
    {
      icon: <SetupIcon />,
      title: t("Setup"),
      text: t(
        "Whether you use a popular eCommerce platform and you require a payment module or you have built your website from scratch and you need us to host your payment page, at Twispay the setup is free and will always stay that way!"
      ),
      rate: t("Included"),
      per: "No setup-specific fees",
    },
    {
      icon: <TransactionIcon />,
      title: t("Transaction"),
      text: intl.formatMessage(
        {
          id:
            "This flat transaction fee is applied regardless of the charged amount. May it be 50 or 5,000 Euros, every successful transaction entails the same flat transaction fee.",
        },
        {
          currency: countryCode === "ro" ? "RON" : "€",
        }
      ),
      rate: isWithComma ? "0,10" : "0.10",
      per: "per successful transaction",
    },
    {
      icon: <RefundIcon />,
      title: t("Refund"),
      text: t(
        "Dissapointing as it may seem, there will always be customers who require refunds. Make sure you keep your refund policy up to date and treat every customer request with heart-felt care."
      ),
      rate: isWithComma ? "0,50" : "0.50",
      per: "per successful refund",
    },
    {
      icon: <PayoutIcon />,
      title: t("Payout"),
      text: t(
        "We charge a flat payout fee, regardless of the settled amount. Every settlement will be timely and your money will be safeguarded above and beyond industry standards at every stage throughout the involved digital systems."
      ),
      rate: countryCode === "ro" ? "0" : "5",
      per: "for min. payout amount of 500",
    },
  ]
  return (
    <div>
      <div className="columns is-multiline is-gapless">
        {steps.map((step, i) => (
          <div
            key={step.title}
            className="column is-half has-margin-bottom-6 has-margin-bottom-0-mobile"
          >
            <hr className="is-hidden-tablet" />
            <div className="title-wrapper is-flex is-vcentered is-hspaced">
              <h5 className="title has-margin-0-mobile is-4 is-flex is-vcentered">
                <span className="icon">{step.icon}</span>
                {step.title}
              </h5>
              <span className="is-size-3 is-hidden-tablet rate-value">
                {step.rate} {i === 0 ? "" : countryCode === "ro" ? "RON" : "€"}
              </span>
            </div>
            <div className="content-wrapper is-hidden-tablet">
              <p className="has-text-secondary has-margin-bottom-5">
                {step.per}
              </p>
              <p
                className={`${
                  i !== steps.length - 1 ? "has-margin-bottom-5" : ""
                }`}
              >
                {step.text}
              </p>
            </div>
            <div className="content-wrapper is-hidden-mobile">
              <p className="has-margin-bottom-4">{step.text}</p>
              <span className="is-size-3 rate-value">
                {step.rate} {i === 0 ? "" : countryCode === "ro" ? "RON" : "€"}
              </span>
              <p className="rate-desc is-size-7">
                {intl.formatMessage(
                  {
                    id: step.per,
                  },
                  {
                    "500": <>500 {countryCode === "ro" ? "RON" : "€"}</>,
                  }
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
const HeroSection = ({ countryCode, isLoading }) => {
  const intl = useIntl()
  const { t } = useTranslation()

  const isWithComma = intl.locale === "ro" || intl.locale === "it"
  const benefits = [
    {
      icon: <SetupIcon />,
      text: intl.formatMessage(
        {
          id: "€0,00 Setup fee",
        },
        {
          amount:
            countryCode === "ro"
              ? isWithComma
                ? "0,00 RON"
                : "0.00 RON"
              : isWithComma
              ? "€0,00"
              : "€0.00",
        }
      ),
    },
    {
      icon: <TransactionIcon />,
      text: intl.formatMessage(
        {
          id: "€0,10 Transaction fee",
        },
        {
          amount:
            countryCode === "ro"
              ? isWithComma
                ? "0,1 RON"
                : "0.1 RON"
              : isWithComma
              ? "€0,10"
              : "€0.10",
        }
      ),
    },
    {
      icon: <RefundIcon />,
      text: intl.formatMessage(
        {
          id: "€0,50 Refund fee",
        },
        {
          amount:
            countryCode === "ro"
              ? isWithComma
                ? "0,5 RON"
                : "0.5 RON"
              : isWithComma
              ? "€0,50"
              : "€0.50",
        }
      ),
    },
    {
      icon: <PayoutIcon />,
      text: intl.formatMessage(
        {
          id: "€5,00 Payout fee",
        },
        {
          amount:
            countryCode === "ro"
              ? isWithComma
                ? "0 RON"
                : "0 RON"
              : isWithComma
              ? "€5,00"
              : "€5.00",
        }
      ),
    },
  ]
  return (
    <section className="section is-hero ">
      <div className="container">
        <h1 className="title main-title is-spaced is-2 has-text-centered main-title has-margin-bottom-3 has-margin-bottom-4-mobile">
          {t("Standard Merchant Rates")}
        </h1>
        <h2 className="subtitle main-subtitle is-4 is-size-7-mobile has-margin-bottom-6 has-text-centered">
          {t(
            "The prices listed on this page may vary according to your type of business, processing history and other relevant variables"
          )}
        </h2>
        <div className="columns is-centered is-mobile">
          <div className="column  is-full-desktop is-11-widescreen is-9-fullhd">
            <div className="columns">
              <div className="column is-7-desktop">
                {/* {isLoading ? (
                  <></>
                ) : countryCode === "ro" ? (
                  <Offline2OnlineCard />
                ) : ( */}
                <div className="is-card is-borderless">
                  <div className="title-wrapper has-text-centered is-flex is-centered-centered">
                    <h4 className="title ">{t("Standard")}</h4>
                  </div>
                  <div className="content-wrapper has-text-centered-mobile is-flex is-vspaced ">
                    <div className="columns">
                      <div className="column">
                        <p className="has-text-blue">
                          {t("Service fee starting from")}
                        </p>
                        <h5 className="has-text-weight-medium rate">
                          <span>
                            {isLoading ? (
                              <></>
                            ) : countryCode === "ro" ? (
                              isWithComma ? (
                                "0,99"
                              ) : (
                                "0.99"
                              )
                            ) : isWithComma ? (
                              "1,3"
                            ) : (
                              1.3
                            )}
                          </span>
                          <sup>%</sup>
                        </h5>
                        <p className="has-text-blue">
                          {t("per successful transaction")}
                        </p>
                      </div>
                      <div className="column is-flex is-vspaced benefits-wrapper">
                        {benefits.map(benefit => (
                          <div
                            key={benefit.text}
                            className="is-flex is-vcentered has-margin-bottom-1"
                          >
                            <span className="icon">{benefit.icon}</span>
                            <span className="is-size-5 has-margin-left-4 has-text-left">
                              {isLoading ? null : benefit.text}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <Link
                      to="/price-calculator"
                      className="button is-rounded is-success is-fullwidth is-medium"
                    >
                      {t("Get Started")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="column ">
                <div className="is-card is-fullheight is-borderless right-card">
                  <div className="title-wrapper has-text-centered is-flex is-centered-centered">
                    <h4 className="title ">{t("Custom")}</h4>
                  </div>
                  <div className="content-wrapper  has-text-centered">
                    <div>
                      <p className="is-size-5">
                        {t("Transaction volume exceeding")}
                      </p>
                      <h5 className=" has-margin-bottom-4 custom-value">
                        <sup className="left">€</sup>{" "}
                        <span>{isWithComma ? "30,000" : "30.000"}</span>
                        <sup className="right is-size-5 is-size-6-mobile">
                          /{t("month")}
                        </sup>
                      </h5>
                      <p className="is-size-6 content-text">
                        {t(
                          "Together, we will tailor an offer to suit your requirements"
                        )}
                      </p>
                    </div>
                    <Link
                      to="/contact"
                      className="button is-rounded is-primary is-fullwidth is-medium"
                    >
                      {t("Contact Sales")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const CurrenciesSection = () => {
  const { t } = useTranslation()
  const currencies = ["EUR", "USD", "NOK", "SEK", "DKK", "RON", "HUF"]
  return (
    <section className="section currencies-section">
      <div className="container has-text-centered">
        <h5 className="title is-5 is-size-6-mobile">
          {t("We Process Payments in Multiple Currencies")}
        </h5>
        <div className="columns is-centered">
          <div className="column is-three-quarters ">
            <div className="is-flex is-hcentered is-wrap">
              {currencies.map(currency => (
                <span key={currency} className="has-text-weight-bold currency">
                  {currency}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const DetailedRatesSection = ({ countryCode, isLoading }) => {
  const { t } = useTranslation()
  return (
    <section id="rates-section" className="section rates-section">
      <div className="container">
        <h4 className="title main-title has-text-weight-medium has-text-centered is-size-4-mobile is-spaced has-margin-bottom-3">
          {t("Detailed Merchant Rates")}
        </h4>
        <p
          style={{ marginBottom: 21 }}
          className="subtitle is-7 has-text-centered "
        >
          {t("Service fees starting from*")}
        </p>
        <div className="columns is-centered">
          <div className="column is-11-desktop">
            <hr className="is-hidden-mobile" />
            <MethodsTable countryCode={countryCode} isLoading={isLoading} />
            <hr className="is-hidden-mobile" />

            <StepsTable countryCode={countryCode} />
          </div>
        </div>
      </div>
    </section>
  )
}

const Section5 = () => {
  const { t } = useTranslation()
  const intl = useIntl()
  return (
    <section className="section section-5">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-10-fullhd">
            <div className="content-wrapper">
              <div className="stripes-wrapper is-hidden-mobile">
                <img src={DashboardStripes} alt="stripes" />
              </div>
              <div className="columns">
                <div className="column is-two-fifths">
                  <div className="dashboard-img">
                    <Building />
                  </div>
                </div>
                <div className="column">
                  <div
                    style={{ height: "100%" }}
                    className="is-flex is-vspaced"
                  >
                    <div>
                      <h2 className="title main-title is-spaced has-margin-bottom-4">
                        {t(
                          "Enterprise-Level, Different or Complex Business Model?"
                        )}
                      </h2>
                      <p className="subtitle is-6 main-subtitle has-margin-bottom-5">
                        {intl.formatMessage(
                          {
                            id:
                              "Do you process more than 30,000 Euro per month? You might qualify for custom-volume pricing. Do you have unusually large orders or a complex business model that requires our assistance?",
                          },
                          {
                            currency: "€",
                          }
                        )}
                        {t("We have you covered.")}
                      </p>
                    </div>
                    <div className="ctas-wrapper is-flex is-vcentered ">
                      <Link
                        to="/contact"
                        className="button is-size-5 is-blue is-rounded "
                      >
                        {t("Contact Sales")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const PricingPage = () => {
  const { t } = useTranslation()
  const { countryCode, isLoading } = useCountryCode()
  return <NotFoundPage />
  // return (
  //   <Layout stripeColor="#382987" pageName="pricing">
  //     <SEO title={`Twispay - ${t("Online payment processing fees")}`} />
  //     <HeroSection countryCode={countryCode} isLoading={isLoading} />
  //     <CurrenciesSection />
  //     <DetailedRatesSection countryCode={countryCode} isLoading={isLoading} />
  //     <Section5 />
  //   </Layout>
  // )
}

export default PricingPage
